/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Layout from "./layout"
import Style from "./page-layout.module.scss"

const PageLayout = ({
  children,
  title,
  enTitle
}) => {
  return (
    <Layout>
      <div className={Style.content}>
        <h1 className={Style.title}>
          <ruby className={Style.ruby}>
            {title}
            <rt className={Style.en}>{enTitle}</rt>
          </ruby>
        </h1>
        {children}
      </div>
    </Layout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
